import moment from 'moment';

import type { FishHealthMetaCardProps } from '@/components/atoms/FishHealthMetaCard/FishHealthMetaCard';
import { CalculateFishWoundingBox } from '@/components/atoms/PoiInfoLayer/PoiInfoLayerFunctions';
import type { Image as ViewImageType } from 'components/organisms/ImageViewer/ImageViewContainer';
export interface Image {
    id: number;
    timeStamp: string;
    path: string;
}

export function mapImageUrl(image: Image): string {
    const { path } = image;
    /*
	const urlsArray = image.path.split('/');
	const imageId = urlsArray[urlsArray.length - 1];
	const welfareId = urlsArray[urlsArray.length - 3];
	*/

    // return `${process.env.REACT_APP_BACKEND_URL}/images/${image.id}`;
    // return `http://localhost:6006/${path}`
    return `${path}`;
}

export function mapImpagesToMetaCardInfoObjects(images: ViewImageType[]) {
    return images.flatMap((image) => {
        return image.bboxes?.map((bbox) => {
            const { tag, score, size_x_mm, size_y_mm } = bbox;
            const { height, width } = image;
            const poiData = CalculateFishWoundingBox({ bbox, dimensions: { height, width } });
            return {
                tag,
                score,
                size_x_mm,
                size_y_mm,
                image_id: image.id,
                poiData,
            } as unknown as FishHealthMetaCardProps;
        });
    });
}

export interface NavigationCardInfo {
    healing: number;
    active: number;
    daysOld: number;
    imageId: string;
}
export function mapImpagesToNavigationCardInfoObjects(
    images: ViewImageType[]
): NavigationCardInfo[] {
    return images.map((image) => {
        const cardObjejct = {
            healing: image.bboxes?.filter((bbox) => bbox.tag === 'healing_wound').length,
            active: image.bboxes?.filter((bbox) => bbox.tag === 'wound').length,
            movable: image.bboxes?.filter((bbox) => bbox.tag === 'movable').length,
            skottelus:
                image.bboxes.filter((bbox) => bbox.tag === 'skottelus').length +
                image.bboxes.filter((bbox) => bbox.tag === 'skottelus_2').length,
            adult_female_lice: image.bboxes?.filter((bbox) => bbox.tag === 'adult_female_lice')
                .length,

            daysOld: image.timeStamp
                ? Math.floor((Date.now() - Date.parse(image.timeStamp)) / 86400000)
                : 0,
            imageId: image.id,
            path: image.path,
        };

        const twoMovablesCount = image.bboxes?.filter((bbox) => bbox.tag === 'two_movables').length;
        cardObjejct.movable = cardObjejct?.movable + (twoMovablesCount ? twoMovablesCount * 2 : 0);

        return cardObjejct;
    });
}

export function mapImageToScpCopyCommand({
    bioscopeId,
    timeStamp,
    fishId,
}: {
    bioscopeId?: number;
    timeStamp: string;
    fishId?: number;
}) {
    const stamp = moment(timeStamp).tz('UTC');
    const folderStamp = stamp.format('YYYY_MM_DD');
    const fileStamp = `B${bioscopeId}_${stamp.format('YYYY_MM_DD_HH_mm_ss_SSS')}_${fishId}`;
    const baseFolder = `${bioscopeId}:/store/optoscale/bioscopeData`;
    return `scp ${baseFolder}/lice/${folderStamp}/pickles/lice_crop_${fileStamp}.gz ${baseFolder}/lice/${folderStamp}/pickles_wound/lice_crop_${fileStamp}.gz .`;
}

export interface dimensions {
    width: number;
    height: number;
}

export interface mousePosition {
    x: number;
    y: number;
}

export interface bbox {
    tag: string;
    bbox: number[];
    size_x_mm?: number;
    size_y_mm?: number;
    score?: number;
}

type DateObject = Record<string, { day: string; count: number }[]>;

export const FILTER_TAGS = [
    {
        title: 'All',
        tag: 'all',
        filter: (image: ViewImageType) => handleAllFilters(image),
    },
    {
        title: 'Active',
        tag: 'wound',
        filter: (image: ViewImageType) => image.bboxes.some((bbox) => bbox.tag === 'wound'),
    },
    {
        title: 'Healing',
        tag: 'healing_wound',
        filter: (image: ViewImageType) => image.bboxes.some((bbox) => bbox.tag === 'healing_wound'),
    },
    {
        title: 'Small',
        tag: 'small',
        filter: (image: ViewImageType) =>
            image.bboxes.some((bbox) => bbox.size_x_mm < 20 || bbox.size_y_mm < 20),
    },
    {
        title: 'Medium',
        tag: 'medium',
        filter: (image: ViewImageType) =>
            image.bboxes.some(
                (bbox) =>
                    (bbox.size_x_mm >= 20 && bbox.size_x_mm < 50) ||
                    (bbox.size_y_mm >= 20 && bbox.size_y_mm < 50)
            ),
    },
    {
        title: 'Large',
        tag: 'large',
        filter: (image: ViewImageType) =>
            image.bboxes.some((bbox) => bbox.size_x_mm >= 50 || bbox.size_y_mm >= 50),
    },
    {
        title: 'Mature',
        tag: 'maturation_tag',
        filter: (image: ViewImageType) => image.indicators?.maturity >= 0.5,
    },
];

const handleAllFilters = (image: ViewImageType) => {
    return (
        image.bboxes?.some((bbox) => bbox.tag === 'wound') ||
        image.bboxes?.some((bbox) => bbox.tag === 'healing_wound')
    );
};

export const imagesByTag = (images, filterTags) => {
    return filterTags.reduce(
        (acc, tag) => {
            acc[tag.tag] = images.filter(tag.filter);
            return acc;
        },
        {} as Record<string, typeof images>
    );
};

export const dateListByTag = (
    imagesByTag: Record<string, { day: string }[]>,
    filterTags: { tag: string }[]
) => {
    return filterTags.reduce((acc, tag) => {
        const dateObjs = imagesByTag[tag.tag].reduce(
            (dates, image) => {
                if (dates[image.day] === undefined) {
                    dates[image.day] = {
                        count: 0,
                        day: moment(image.day).format('YYYY-MM-DD'),
                    };
                }
                dates[image.day].count++;
                return dates;
            },
            {} as Record<string, { day: string; count: number }>
        );
        const dateList = Object.values(dateObjs).sort((a, b) => (a.day < b.day ? -1 : 1));
        acc[tag.tag] = dateList;

        return acc;
    }, {} as DateObject);
};
