import React, { useMemo, useState } from 'react';
import { Dropdown, DropdownToggle } from 'reactstrap';
import cn from 'classnames';

import PageActionButtons from '@/components/molecules/PageActionButtons';
import PageHeaderBar from '@/components/molecules/PageHeaderBar';
import { LiceReportLocations } from '@/services/types';
import { DownloadCsvXlsx } from 'components/DownloadCsvXlsx';
import { DownloadPDF } from 'components/DownloadPDF/DownloadPDFButton';

import Location from '../Location/Location';

import styles from './LocationContainer.module.scss';

const cx = cn.bind(styles);

interface DisplayLocationsProps {
    locations: LiceReportLocations[];
    weekNumber: string;
    openDefault: boolean;
    clientName: string;
    clientId: number;
}

export const LocationContainer = ({
    locations,
    weekNumber,
    openDefault,
    clientName,
    clientId,
}: DisplayLocationsProps) => {
    const [open, setOpen] = React.useState(openDefault);

    const locationsWithTotals = useMemo(() => {
        return locations.map((location) => ({
            ...location,
            cages: location.cages.map((cage) => ({
                ...cage,
                femaleTotal: cage.femaleAvg * cage.count,
                caligusElongatusTotal: cage.caligusElongatusAvg * cage.count,
                movingStageTotal: cage.movingStageAvg * cage.count,
            })),
        }));
    }, [locations]);

    const [locationsInclude, setlocationIncluded] = useState(
        locationsWithTotals.map((l) => ({
            ...l,
            cages: l.cages.map((c) => ({ ...c, isIncluded: true })),
        }))
    );
    const PATH = `/c/${clientId}/cages/v2`;

    if (!locations.length) {
        return null;
    }

    const DownloadData = () => {
        const filteredCagesLocation = locationsInclude.map((l) => ({
            ...l,
            cages: l.cages.filter((c) => c.isIncluded),
        }));
        const allData =
            filteredCagesLocation?.flatMap((location) => {
                const formatCage = (cage: (typeof location.cages)[0]) => {
                    const dataWithLice = {
                        location_name: location?.name,
                        pen_name: cage?.name,
                        female_avg: cage?.femaleAvg?.toFixed(2) ?? '',
                        female_total: cage?.femaleTotal?.toFixed(0) ?? '',
                        moving_stage_avg: cage?.movingStageAvg?.toFixed(2) ?? '',
                        moving_stage_total: cage?.movingStageTotal?.toFixed(0) ?? '',
                        caligus_elongatus_avg: cage?.caligusElongatusAvg?.toFixed(2) ?? '',
                        caligus_elongatus_total: cage?.caligusElongatusTotal?.toFixed(0) ?? '',
                        count: cage?.count ?? '',
                        living_weight: `${cage?.livingWeight?.toFixed(0)} g` ?? '',
                        depth_avg: cage?.depthAvg?.toFixed(1) ?? '',
                    };
                    return { ...dataWithLice };
                };
                const result = location.cages.flatMap(formatCage);

                //calculating average of each Location
                const average = calculateAverage(location.cages);

                const formattedAverage = formatCage(average);

                formattedAverage.count = `${average.count} (Total)`;

                return [
                    ...result,
                    formattedAverage,
                    {
                        location_name: '',
                        pen_name: '',
                        female_avg: '',
                        female_total: '0',
                        moving_stage_avg: '',
                        moving_stage_total: '0',
                        caligus_elongatus_avg: '',
                        caligus_elongatus_total: '0',
                        count: '',
                        living_weight: '',
                        depth_avg: '',
                    },
                ];
            }) ?? [];

        const dataColumnNames = allData.length > 0 ? Object.keys(allData[0]) : [];

        const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);

        const generatePDFContent = async () => {
            const { LicePDFContent } = await import('../../LicePDFContent');

            return (
                <LicePDFContent clientName={clientName} allData={allData} weekNumber={weekNumber} />
            );
        };

        return (
            <PageHeaderBar>
                <PageActionButtons>
                    <DownloadCsvXlsx
                        label="CSV"
                        data={allData}
                        nameToInclude={'SiteOverview'}
                        COLUMNS={formattedDataColumnNames}
                    />
                    <DownloadCsvXlsx
                        label="XLSX"
                        data={allData}
                        nameToInclude={'SiteOverview'}
                        COLUMNS={formattedDataColumnNames}
                    />
                    <DownloadPDF
                        buttonText={'PDF'}
                        docGenerator={generatePDFContent}
                        fileName={`${clientName}-Lice-report-${weekNumber}`}
                    />
                </PageActionButtons>
            </PageHeaderBar>
        );
    };

    const handleCageSelected = (cage, locationId) => {
        setlocationIncluded(
            locationsInclude.map((l) =>
                l.id === locationId
                    ? {
                          ...l,
                          cages: l.cages.map((c) =>
                              c.cageId === cage.cageId
                                  ? { ...c, isIncluded: !c.isIncluded }
                                  : { ...c }
                          ),
                      }
                    : { ...l }
            )
        );
    };

    const handleAllCagesSelected = (locationId, isSelected) => {
        setlocationIncluded(
            locationsInclude.map((l) =>
                l.id === locationId
                    ? {
                          ...l,
                          cages: l.cages.map((c) => ({ ...c, isIncluded: isSelected })),
                      }
                    : { ...l }
            )
        );
    };
    const calculateAverage = (cages) => {
        const includedCages = cages.filter((c) => c.isIncluded);
        //neglecting undefine depth for while calculating average of depth
        //because of defective depth sensor
        const depthAvgDivider = includedCages.filter((c) => c.depthAvg).length;
        return includedCages.reduce(
            (res, curr) => {
                if (!curr.isIncluded) return { ...res };
                return {
                    count: res.count + curr.count,
                    depthAvg: res.depthAvg + curr.depthAvg / depthAvgDivider,
                    depthMax: res.depthMax + curr.depthMax / depthAvgDivider,
                    depthMin: res.depthMin + curr.depthMin / depthAvgDivider,
                    femaleAvg: res.femaleAvg + curr.femaleAvg / includedCages.length,
                    caligusElongatusAvg:
                        res.caligusElongatusAvg + curr.caligusElongatusAvg / includedCages.length,
                    movingStageAvg: res.movingStageAvg + curr.movingStageAvg / includedCages.length,
                    livingWeight: res.livingWeight + curr.livingWeight / includedCages.length,
                    femaleTotal: res.femaleTotal + curr.femaleTotal,
                    caligusElongatusTotal: res.caligusElongatusTotal + curr.caligusElongatusTotal,
                    movingStageTotal: res.movingStageTotal + curr.movingStageTotal,
                };
            },
            {
                count: 0,
                depthAvg: 0,
                depthMax: 0,
                depthMin: 0,
                femaleAvg: 0,
                caligusElongatusAvg: 0,
                movingStageAvg: 0,
                livingWeight: 0,
                femaleTotal: 0,
                caligusElongatusTotal: 0,
                movingStageTotal: 0,
            }
        );
    };

    return (
        <>
            <Dropdown
                toggle={() => {
                    setOpen(!open);
                }}>
                <DropdownToggle
                    tag="h2"
                    onClick={() => {
                        setOpen(!open);
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        marginBottom: '0.6rem',
                        zIndex: '99999',
                        fontSize: '2rem',
                    }}
                    className={
                        open
                            ? 'headingForCollapse negMarginBottom openedCollapse'
                            : 'negMarginBottom  headingForCollapse'
                    }>
                    {weekNumber} &nbsp;
                    <i
                        className={cn({
                            fas: true,
                            'fa-caret-up': open,
                            'fa-caret-down': !open,
                        })}
                    />
                </DropdownToggle>
                <div
                    className={cx({
                        overflowHidden: true,
                        height0: !open,
                        heightAuto: open,
                    })}>
                    <DownloadData />

                    {locationsInclude.map((location) => (
                        <Location
                            key={location.id}
                            cages={location.cages}
                            title={location.name}
                            openDefault={true}
                            locationPath={PATH}
                            locationId={location.id}
                            onHandleCageSelected={handleCageSelected}
                            onHandleAllCagesSelected={handleAllCagesSelected}
                            averages={calculateAverage(location.cages)}
                        />
                    ))}
                </div>
            </Dropdown>
            <div className="weekSeparator" />
        </>
    );
};
