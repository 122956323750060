import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useResizeDetector } from 'react-resize-detector';
import {
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PluginChartOptions,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import cn from 'classnames';
import { saveAs } from 'file-saver';

import OverflowToDropdownlist from '@/components/atoms/OverflowToDropdownlist';
import { SkeletonLoader } from 'components/atoms/SkeletonLoader';
import { ChartHeaderBar } from 'components/molecules/ChartHeaderBar/ChartHeaderBar';

import 'chartjs-adapter-moment';

import { htmlLegendPlugin } from '../plugins/htmlLegendPlugin';

import styles from './OptoMixedChart.module.scss';

ChartJS.register(
    LinearScale,
    TimeScale,
    CategoryScale,
    PointElement,
    LineElement,
    Legend,
    annotationPlugin,
    htmlLegendPlugin,
    Filler,
    zoomPlugin,
    Tooltip,
    Title
);

ChartJS.register({
    id: 'noData',
    afterDraw: (chart) => {
        if (chart.data.datasets.flatMap((x) => x.data).every((item) => item === 0)) {
            const ctx = chart.ctx;
            const width = chart.width;
            const height = chart.height;

            chart.clear();
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText('No data to display', width / 2, height / 2);
            ctx.restore();
        }
    },
});

export interface ChartActionButtons {
    copyAsPng?: boolean;
    resetZoom?: boolean;
    downloadAsPng?: boolean;
    fullScreen?: boolean;
    timeScaleSwitch?: boolean;
    timeSwitchWeekly?: boolean;
}

export type OptoMixedChartProps = {
    chartName?: string;
    graphType?: 'line';
    chartData: ChartData<'line'>;
    chartOptions?: (data: ChartData<'line'>) => ChartOptions<'line'>;
    pluginOptions?: PluginChartOptions<'line'>;
    loading?: boolean;
    actionButtons?: ChartActionButtons;
};

const OptoMixedChart = ({
    chartName = '',
    chartData,
    chartOptions,
    loading = false,
    actionButtons = {
        copyAsPng: true,
        resetZoom: true,
        downloadAsPng: true,
        fullScreen: true,
        timeScaleSwitch: false,
    },
}: OptoMixedChartProps) => {
    const options = chartOptions(chartData);
    const chartRef = useRef<ChartJS<'line'>>(null);
    const chartBoundaries = useRef<HTMLDivElement>(null);

    const [chart, setChart] = useState(null);
    const [isFullScreen, setisFullscreen] = useState(false);
    const [topheading, setTopheading] = useState(false);
    const [lineOptions, setOptions] = useState(options);
    const { width } = useResizeDetector({
        handleWidth: true,
        targetRef: chartBoundaries,
        onResize: () => {
            if (width && width > 600 && !topheading) {
                setTopheading(true);
                chart.options.plugins.title.display = false;
                chart.update();
                chart.resize();
            } else if (width && width < 600 && topheading) {
                setTopheading(false);
                chart.options.plugins.title.display = true;
                chart.update();
                chart.resize();
            }
        },
    });

    useEffect(() => {
        if (chart) {
            chart.options = options;
            chart.update();
        }
    }, [options, chart]);

    const showAsFullScreen = () => {
        if (chartBoundaries.current) {
            isFullScreen ? document.exitFullscreen() : chartBoundaries.current.requestFullscreen();
            chartBoundaries.current.onfullscreenchange = () => {
                chart?.resize();
                setisFullscreen(!isFullScreen);
            };
            return;
        }
    };

    const switchButtonandler = (e) => {
        const { ticks, type } = options.scales.x;
        options.scales.x = {
            type,
            time: {
                unit: e.currentTarget.name,
            },
            ticks,
        };
        //setOptions(options);
        chart.update();
    };

    const downloadAsPngHandler = () => {
        const dateString = new Date().toISOString().split('T')[0];
        chart.canvas.toBlob((blob) =>
            saveAs(blob, chartName.length > 0 ? `${chartName}-${dateString}.png` : 'chart.png')
        );
    };

    const copyAsPngHandler = async (ev) => {
        if (!chart) {
            console.warn('No chart to copy');
            return;
        }
        const response = await chart.canvas.toBlob((blob) =>
            navigator.clipboard.write([new window.ClipboardItem({ 'image/png': blob })])
        );
        // TODO - show some kind of notification
        console.log('copied to clipboard', response);
    };

    const resetZoomHandler = () => {
        chart?.resetZoom();
    };

    /**
     * Modify chart options on mount
     */
    useEffect(() => {
        if (chartRef.current) {
            setChart(chartRef.current as ChartJS<'line'>);
            setTopheading(chartRef.current.options.plugins.title.position === 'top');
            chartRef.current.update();
        }

        chartRef.current?.update();
    }, [chartRef, chartBoundaries, chartData, lineOptions]);

    return (
        <SkeletonLoader loading={loading} name={`${chartName} loading..`}>
            <div className={styles.optomixedchart} ref={chartBoundaries}>
                <div className={styles.chartHeaderSection}>
                    <div className={styles.chartTitle}>
                        {topheading && <h2>{options.plugins.title.text}</h2>}
                    </div>
                    <OverflowToDropdownlist>
                        <div
                            data-title={chartName}
                            className={cn(styles.htmlLegendBar, 'legend-container')}
                        />
                    </OverflowToDropdownlist>
                    <ChartHeaderBar
                        visibleButtons={actionButtons}
                        chart={chart}
                        fullScreenHandler={showAsFullScreen}
                        downloadAsPngHandler={downloadAsPngHandler}
                        copyAsPngHandler={copyAsPngHandler}
                        resetZoomHandler={resetZoomHandler}
                        switchButtonsHandler={switchButtonandler}
                        isFullScreen={isFullScreen}
                    />
                </div>

                <div className={cn(styles.tooltipContainer, 'tooltip-container')} />
                <Line
                    data={chartData}
                    aria-description="bioscope chart data"
                    aria-label="bioscope chart data"
                    plugins={[htmlLegendPlugin]}
                    options={lineOptions}
                    ref={chartRef}
                />
            </div>
        </SkeletonLoader>
    );
};

export default OptoMixedChart;
export { OptoMixedChart };
