import React from 'react';
import { useTranslation } from 'react-i18next';
import type { match } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Alert } from 'reactstrap';

import MeContext from '../contexts/meContext';

import Client from './Client';

interface ClientsProps {
    match: match;
}

const Clients = ({ match }: ClientsProps) => {
    const { state } = React.useContext(MeContext);
    const { t } = useTranslation();

    if (state.clients.length === 0) {
        return (
            <div>
                <Alert>{t('No access')}</Alert>
            </div>
        );
    }

    return (
        <Switch>
            <Route path={`${match.path}/:clientId`} component={Client} />
            <Redirect to={`${match.path}/${state.clients[0].id}`} />
        </Switch>
    );
};

export default Clients;

/*
(match: match) => {
                    return (
                        <>
                            <PageTitleForAnalytics title="Client" category="global" />
                            <Client match={match} />
                        </>
                    );
                }

*/
