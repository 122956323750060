import React, { useEffect, useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { t } from 'i18next';

import Loader from '@/components/atoms/Loader';
import { ClientLocations } from '@/services/types';

import styles from './LocationsAndCages.module.scss';

export interface Cage {
    id: number;
    historical?: string | null;
    name: string;
    activeBioscopes?: number[];
}

export interface Location {
    id: number;
    name: string;
    cages: Cage[];
}

export interface LocationsAndCagesProps {
    currentLocationName: string;
    currentCageName: string;
    clientId: number;
    isLoading: boolean;
    locations: ClientLocations[];
    fishTypeName?: string;
    history?: any;
    lastPart: string;
}

const compareCageNames = (a: string, b: string) => {
    const norwegianSpecialLetters = ['æ', 'ø', 'å'];

    const isNumericA = /^\d+$/.test(a); // checks if the string a is made up entirely of numbers
    const isNumericB = /^\d+$/.test(b);

    if (isNumericA && isNumericB) {
        return Number(a) - Number(b); // Compare numerically
    }

    if (isNumericA) return -1; // Put numeric values first
    if (isNumericB) return 1;

    const aFirstChar = a[0].toLowerCase();
    const bFirstChar = b[0].toLowerCase();

    const aIsSpecial = norwegianSpecialLetters.includes(aFirstChar);
    const bIsSpecial = norwegianSpecialLetters.includes(bFirstChar);

    if (aIsSpecial && bIsSpecial) {
        return a.localeCompare(b); // Compare special letters alphabetically
    }

    if (aIsSpecial) return -1; // Special letters first
    if (bIsSpecial) return 1;

    return a.localeCompare(b); // Compare alphabetically
};

const LocationsAndCages = ({
    currentLocationName,
    currentCageName,
    clientId,
    isLoading,
    locations,
    history = [],
    lastPart = '',
}: LocationsAndCagesProps) => {
    const [currentLocation, setCurrentLocation] = useState(currentLocationName);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
    const [cageDropdownOpen, setCageDropdownOpen] = useState(false);
    const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
    const [hasLocationChanged, setHasLocationChanged] = useState(false);
    const [cagesSortedByStatus, setCagesSortedByStatus] = useState<{
        active: Cage[];
        inactive: Cage[];
        historical: Cage[];
    }>({ active: [], inactive: [], historical: [] } as {
        active: Cage[];
        inactive: Cage[];
        historical: Cage[];
    });

    const [locationsSortedByStatus, setLocationsSortedByStatus] = useState<{
        active: Location[];
        inactive: Location[];
    }>({ active: [], inactive: [] } as {
        active: Location[];
        inactive: Location[];
    });

    useEffect(() => {
        const location = locations.find((loc) => loc.name === currentLocation);

        const sortedCagesByStatus = location?.cages.reduce(
            (acc, cage) => {
                if (cage.current_bioscope === null && cage.historical === null) {
                    acc.inactive.push(cage);
                } else if (cage.historical !== null && cage?.historical?.length > 0) {
                    acc.historical.push(cage);
                } else {
                    acc.active.push(cage);
                }
                return acc;
            },
            { active: [], inactive: [], historical: [] }
        );

        // Sort each type of cages by name
        if (sortedCagesByStatus) {
            sortedCagesByStatus.active.sort((a, b) => compareCageNames(a.name, b.name));
            sortedCagesByStatus.inactive.sort((a, b) => compareCageNames(a.name, b.name));
            sortedCagesByStatus.historical.sort((a, b) => compareCageNames(a.name, b.name));
        }

        const sortedLocationsByStatus = locations?.reduce(
            (acc, location) => {
                const hasActiveBioScopes = location.cages.some(
                    (cage) => cage.current_bioscope !== null
                );
                if (!hasActiveBioScopes) {
                    acc.inactive.push(location);
                } else {
                    acc.active.push(location);
                }
                return acc;
            },
            { active: [], inactive: [] }
        );

        setCagesSortedByStatus(sortedCagesByStatus);
        setLocationsSortedByStatus(sortedLocationsByStatus);

        setSelectedLocation(location || null);
        if (hasLocationChanged) {
            setCageDropdownOpen(true);
        }
    }, [currentLocation, locations, hasLocationChanged]);

    const handleDropdownItemClick = (location: Location) => {
        setCurrentLocation(location.name);
        setSelectedLocation(location);
        setHasLocationChanged(true);
    };

    const renderLocationDropdownItems = () => {
        return (
            <>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div data-location="all">
                            <DropdownItem onClick={() => history.push(`/c/${clientId}/cages/v3`)}>
                                {t('All locations')}
                            </DropdownItem>
                        </div>
                        <div data-location="sorted">
                            <span>
                                <DropdownItem divider />
                                <DropdownItem header={true}>{t('Active locations')}</DropdownItem>
                                {locationsSortedByStatus?.active.map((location) => (
                                    <DropdownItem
                                        key={location.id}
                                        onClick={() => handleDropdownItemClick(location)}>
                                        {location.name}
                                    </DropdownItem>
                                ))}
                            </span>
                            {locationsSortedByStatus?.inactive.length > 0 && (
                                <span>
                                    <DropdownItem divider />
                                    <DropdownItem header={true}>
                                        {t('Inactive locations')}
                                    </DropdownItem>
                                    {locationsSortedByStatus?.inactive.map((location) => (
                                        <DropdownItem
                                            key={location.id}
                                            onClick={() => handleDropdownItemClick(location)}>
                                            {location.name}
                                        </DropdownItem>
                                    ))}
                                </span>
                            )}
                        </div>
                    </>
                )}
            </>
        );
    };

    const renderCageDropdownItems = () => {
        if (
            cagesSortedByStatus?.active.length === 0 &&
            cagesSortedByStatus?.inactive.length === 0
        ) {
            return <DropdownItem>{t('No cages available')}</DropdownItem>;
        }

        return (
            <>
                <span>
                    <DropdownItem header={true}>{t('Active cages')}</DropdownItem>
                    {cagesSortedByStatus?.active.map((cage) => (
                        <DropdownItem
                            key={cage.id}
                            onClick={() => {
                                history.push(`/c/${clientId}/cages/v3/${cage.id}/${lastPart}`);
                            }}>
                            {cage.name}
                        </DropdownItem>
                    ))}
                </span>
                {cagesSortedByStatus?.inactive.length > 0 && (
                    <span>
                        <DropdownItem divider />
                        <DropdownItem header={true}>{t('Inactive cages')}</DropdownItem>
                        {cagesSortedByStatus?.inactive.map((cage) => (
                            <DropdownItem
                                key={cage.id}
                                onClick={() => {
                                    history.push(`/c/${clientId}/cages/v3/${cage.id}/${lastPart}`);
                                }}>
                                {cage.name}
                            </DropdownItem>
                        ))}
                    </span>
                )}
                {cagesSortedByStatus?.historical.length > 0 && (
                    <span>
                        <DropdownItem divider />
                        <DropdownItem header={true}>{t('Historical cages')}</DropdownItem>
                        {cagesSortedByStatus?.historical.map((cage) => (
                            <DropdownItem
                                key={cage.id}
                                onClick={() => {
                                    history.push(`/c/${clientId}/cages/v3/${cage.id}/${lastPart}`);
                                }}>
                                {cage.name}
                            </DropdownItem>
                        ))}
                    </span>
                )}
            </>
        );
    };

    return (
        <div className={styles.locationsAndCages}>
            <UncontrolledDropdown
                isOpen={locationDropdownOpen}
                toggle={() => setLocationDropdownOpen((prev) => !prev)}>
                <DropdownToggle className={styles.dropdownToggle}>
                    {currentLocation}
                    <i className="pl-2 fa fa-caret-down" />
                </DropdownToggle>
                <DropdownMenu
                    className={locationDropdownOpen ? styles.locations_dropdownMenu : ''}
                    direction="down">
                    {renderLocationDropdownItems()}
                </DropdownMenu>
            </UncontrolledDropdown>
            <span data-type="locationCageSeparator">-</span>
            <UncontrolledDropdown
                direction="down"
                isOpen={cageDropdownOpen}
                toggle={() => setCageDropdownOpen((prev) => !prev)}>
                <DropdownToggle className={styles.dropdownToggle}>
                    {hasLocationChanged ? `${selectedLocation.name} ${t('Pens')}` : currentCageName}
                    <i className="pl-2 fa fa-caret-down" />
                </DropdownToggle>
                <DropdownMenu className={cageDropdownOpen ? styles.cages_dropdownMenu : ''}>
                    {renderCageDropdownItems()}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};

export default LocationsAndCages;
export { LocationsAndCages };
