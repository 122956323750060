import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useContentController_Notices } from 'services/hooks';

import NoticeContainer from '@/components/atoms/Notice/NoticeContainer';

import styles from './pageContent.module.scss';

const PageContent = ({
    children,
    breadcrumb,
}: {
    children?: React.ReactNode;
    breadcrumb?: any;
}) => {
    const { i18n } = useTranslation();
    const { data } = useContentController_Notices();

    const dynamicNotices = data?.data?.map((notice) => (
        <NoticeContainer
            key={notice._id}
            heading={notice.title[i18n.language]}
            message={notice.content[i18n.language]}
            variant="default"
            topic="LICE_COUNT.MAX"
            enableNotice={true}
            noticeId={notice._id}
            asToast={true}
        />
    ));

    return (
        <div
            data-pagetype="page-content"
            className={cn(styles.pageContent, {
                [styles.hasBreadcrumb]: !!breadcrumb,
            })}>
            {dynamicNotices}
            {breadcrumb}
            {children}
        </div>
    );
};

export default PageContent;
