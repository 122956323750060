import type React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import { joinUrlPath } from 'utils/urlPath';

import { Navigation, NavigationItem } from 'components/Layout';

import useCage from '../../../../contexts/cage-context';
import useIsPageAvailable from '../../../../hooks/is-page-available';
import { isHidden, type Pages } from '../pages';
// update this list if you want query params to be propagated to the next page
const GENERIC_QUERY_PARAMS = ['from', 'to', 'd', 'w'];

interface CageSideBarNavigationProps {
    pages: Pages;
}

const CageSideBarNavigation: React.Factory<CageSideBarNavigationProps> = ({ pages }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const match = useRouteMatch();
    const cageContext = useCage();

    const nextQuery = useMemo(() => {
        const parsed = queryString.parse(location.search);
        const filtered = GENERIC_QUERY_PARAMS.reduce((acc, key) => {
            acc[key] = parsed[key];
            return acc;
        }, {});

        return queryString.stringify(filtered);
    }, [location.search]);

    const isPageAvailable = useIsPageAvailable();

    const availablePages = useMemo(() => {
        return pages
            .filter(
                (page) => !(!isPageAvailable(page) || isHidden(page, cageContext) || !page?.label)
            )
            .map((page) => {
                const url = joinUrlPath(match?.url, page?.path);
                return {
                    url: `${url}?${nextQuery}`,
                    active: url === location.pathname,
                    ...page,
                };
            });
    }, [isPageAvailable, location.pathname, match, nextQuery, pages, cageContext]);

    const activePage = availablePages.find((page) => page.active === true);
    const label = activePage?.label;
    /*
    useEffect(() => {
        if (label) {
            ReactGA.event({
                category: 'menu interaction',
                action: 'navigate',
                label: label,
            });
        }
    }, [label]);
    */
    return (
        <Navigation>
            {availablePages.map((page) => (
                <NavigationItem key={page.key} to={page.url} active={page.active}>
                    {t(page.label)}
                </NavigationItem>
            ))}
        </Navigation>
    );
};

export default CageSideBarNavigation;
export { CageSideBarNavigation };
