// import { Loader } from 'components/atoms/Loader';
import { useBiomassController_GetFeedingConversionRate } from 'services/hooks';
import { FeedingConversionRateResult, MeasurmentOverviewResult } from 'services/types';
import { getTimeUnitFromTimeRange } from 'utils';
import dayToString from 'utils/dayToString';

import Loader from '@/components/atoms/Loader';
import PageHeaderBar from '@/components/molecules/PageHeaderBar';
import { useMe } from '@/contexts/meContext';
import NoData from 'components/NoData';
import { dayTagValues } from 'components/organisms/OptoGraphs/commonOptoChartHelpers';
import SomethingHappened from 'components/SomethingHappened';

import useCage from '../../../../../contexts/cage-context';
import useCageFilter from '../../../../../contexts/cage-filter-context';
import CagePageTitle from '../../components/LocationsAndCagesNavigation';
import useGraphData from '../Overview2/graph-data-context';

import { PageContent } from './GraphPageContent';

const Graphs = () => {
    const { daypartTag = 'day', error, loading, weightUnitTag, data } = useGraphData();
    const { weightUnitTag: tag, daypartTag: dayPart, to, from } = useCageFilter();
    const { cageId, timezone, loading: cageLoading } = useCage();
    const { isOptoscaleAdmin } = useMe();
    const FeedingConversionRate = useBiomassController_GetFeedingConversionRate(cageId, {
        from: dayToString(from),
        to: dayToString(to),
        weight_unit_tag: weightUnitTag,
    });

    const FeedingConversionRateGraphData = FeedingConversionRate.data
        ?.data as unknown as FeedingConversionRateResult;

    // Fetch chosen daytag from menu date time filter.
    const disabeledDayTags = dayTagValues.filter((dayTag) => dayTag !== dayPart);

    const pageComponentData = {
        disabeledDayTags,
        FeedingConversionRateGraphData,
        loading,
        data: data as MeasurmentOverviewResult,
        weightUnitTag: tag as 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%',
        daypartTag: dayPart,
        isOptoscaleAdmin,
        timeUnitTag: getTimeUnitFromTimeRange(from, to),
        /* NB NB - server does not return the correct dataset in staging. Not able to return from last year */
    };

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <SomethingHappened />;
    }

    if (!data) {
        return <NoData />;
    }

    return (
        <>
            <PageHeaderBar>
                <CagePageTitle />
            </PageHeaderBar>
            <PageContent {...pageComponentData} />
        </>
    );
};

export default Graphs;
export { Graphs };
