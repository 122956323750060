import type { ChartOptions } from 'chart.js';
import { TRANSLATE_THIS_LABEL } from 'utils/formattedDay';
import { getI18n } from 'react-i18next';
import { resizeGraphHandler } from 'components/organisms/OptoGraphs/commonOptoChartHelpers';
const i18n = getI18n();
const defaultBarChartOptions: ChartOptions<'bar'> = {
    layout: {
        padding: 16,
    },
    onResize: resizeGraphHandler,
    resizeDelay: 20,
    responsive: true,
    maintainAspectRatio: true,
    clip: false,
    plugins: {
        legend: {
            position: 'top',
            display: false,
        },
        filler: {
            propagate: false,
        },
        title: {
            display: true,
            text: '',
            position: 'left',
        },
        htmlLegend: {
            containerClass: 'legend-container',
            disabled: false,
            displayLegendValue: true,
            position: 'top',
            showLegend: true,
        },
        zoom: {
            limits: {
                /*
                x: { min: 'original', max: 'original' },
                y: { min: 'original', max: 'original' },
                */
            },
            zoom: {
                wheel: {
                    enabled: false,
                    //modifierKey: 'ctrl',
                },
                pinch: {
                    enabled: true,
                },
                drag: {
                    threshold: 20,
                    enabled: true,
                },
                mode: 'x',
            },
        },
        decimation: {
            enabled: true,
            algorithm: 'lttb',
            samples: 2,
        },
        tooltip: {
            enabled: true,
            position: 'nearest',
            callbacks: {
                label: (context) => `${context.dataset.label} : ${context.formattedValue || ''}`,
                title: (context) => {
                    const timeObj = context[0].raw as { x: number; y: number };
                    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    return Intl.DateTimeFormat(navigator.language, {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                        timeZone: timeZone,
                    }).format(timeObj.x);
                },
            },
            // Using optoScales own tooltip handler
            // external: externalTooltipHandler,
        },
    },

    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day',
            },
            ticks: {
                callback: (value) => {
                    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    const formattedValue = Intl.DateTimeFormat(navigator.language, {
                        month: 'short',
                        day: 'numeric',
                        timeZone: timeZone,
                    }).format(value as number);
                    return i18n.language === 'nb'
                        ? TRANSLATE_THIS_LABEL(formattedValue)
                        : formattedValue;
                },
            },
        },
        y: {
            type: 'linear',
            grace: '5%',
        },
    },
};

export default defaultBarChartOptions;
export { defaultBarChartOptions };
