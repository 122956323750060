import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useCage } from 'contexts/cage-context';

import PageHeaderBar from '@/components/molecules/PageHeaderBar';
import {
    useCageController_UpdateName,
    useCageController_UpdateType,
    useLocationController_CageTypes,
} from '@/services/hooks';
import { Button } from 'components/atoms/Buttons';

import CagePageTitle from '../../components/LocationsAndCagesNavigation';

type Error = {
    key: string;
    message: string;
};

type formField = {
    value: string;
    error: string;
};

type numberFormField = {
    value: number;
    error: string;
};

const Settings = () => {
    const { t } = useTranslation();

    const [error, setError] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [response, setResponse] = useState('');
    const { mutateAsync: updateName, isLoading: loading } = useCageController_UpdateName();
    const { mutateAsync: updateType, isLoading: typeloading } = useCageController_UpdateType();
    const { data: cageTypesList, isLoading } = useLocationController_CageTypes();
    const queryclient = useQueryClient();
    const { cage } = useCage();

    const [formState, setFormState] = useState({
        name: {
            value: cage?.name,
            error: '',
        } as formField,
        cageType: {
            value: cage?.cageType.id ?? -1,
            error: '',
        } as numberFormField,
        id: cage?.id,
    });

    return (
        <>
            <PageHeaderBar>
                <CagePageTitle />
            </PageHeaderBar>
            <Row>
                <Col md={5}>
                    <Card>
                        <CardBody>
                            <Form>
                                <FormGroup>
                                    <Label for="cageName">{t('Change cage name')}:</Label>
                                    <Input
                                        id="cageName"
                                        type="text"
                                        name="name"
                                        placeholder={formState.name.value}
                                        value={formState.name.value}
                                        onChange={(e) => {
                                            setFormState((prevState) => ({
                                                ...prevState,
                                                name: {
                                                    ...prevState.name,
                                                    value: e.target.value as string,
                                                },
                                            }));
                                        }}
                                        invalid={!!error}
                                    />
                                    <Label for="cageType">{t('choose cage type')}:</Label>
                                    <Input
                                        id="cageType"
                                        type="select"
                                        name="cagetype"
                                        placeholder={formState.cageType.value.toString()}
                                        value={formState.cageType.value}
                                        onChange={(e) =>
                                            setFormState((prevState) => ({
                                                ...prevState,
                                                cageType: {
                                                    ...prevState.cageType,
                                                    value: parseInt(e.target.value) as number,
                                                },
                                            }))
                                        }
                                        invalid={!!error}>
                                        <option value={-1}>{`--- ${t(
                                            'choose cage type'
                                        )} ---`}</option>
                                        {cageTypesList?.data.map((cageType) => {
                                            return (
                                                <option key={cageType.id} value={cageType.id}>
                                                    {t(cageType.name)}
                                                </option>
                                            );
                                        })}
                                    </Input>
                                    <FormFeedback>{error}</FormFeedback>
                                </FormGroup>
                                <Button
                                    disabled={isSubmitting}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setIsSubmitting(true);

                                        try {
                                            const nameResp = await updateName({
                                                cageId: formState.id,
                                                requestBody: {
                                                    name: formState.name.value,
                                                },
                                            });
                                            const typeResp = await updateType({
                                                cageId: formState.id,
                                                requestBody: {
                                                    cageTypeId: formState.cageType.value,
                                                },
                                            });

                                            if (
                                                nameResp.status !== 200 ||
                                                typeResp.status !== 200
                                            ) {
                                                setResponse(t('cageupdatefail'));
                                                throw new Error('Failed to update cage settings');
                                            }
                                            setResponse(t('cageupdateok'));

                                            setError(undefined);
                                            setIsSubmitting(false);
                                            queryclient.invalidateQueries(); //We invalidate _everything_ because we don't know what depends on the cage name
                                        } catch (e) {
                                            setIsSubmitting(false);
                                            setError(
                                                t(
                                                    `Something happened: ${
                                                        e?.response?.data?.message ??
                                                        JSON.stringify(e)
                                                    }`
                                                )
                                            );
                                        }
                                    }}>
                                    {t('Change cage settings')}
                                </Button>
                            </Form>
                            <span>{response}</span>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Settings;
export { Settings };
