import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import classnames from 'classnames';

import { Loader } from '@/components/atoms/Loader';
import { SmallDropDown } from '@/components/molecules/SmallDropdown';
import { OverviewCage, OverviewLocation } from '@/services/types';
import { Box } from 'components/Layout';
import {
    autoTimeScale,
    formatAxsis,
    formatTooltip,
    layoutPadding,
    Options,
    startYAxisAtZero,
} from 'components/organisms/OptoGraphs/optionsModifiers';
import {
    ComparingDataMapper,
    defaultLineChartOptions,
    OptoMixedChart,
} from 'components/organisms/OptoGraphs/OptoMixedChart';

import styles from './CompareContentPage.module.scss';

interface CompareContentProps {
    id: string;
    item: string;
    postfixForYAxis: 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%' | 'bl/s' | '°' | '';
    unitType:
        | 'percent'
        | 'weight'
        | 'length'
        | 'number'
        | 'celcius'
        | 'meter'
        | 'height'
        | 'pixel'
        | 'angle';
    multiplier: number;
    descimals: number;
}

const COMPARECONFIG: CompareContentProps[] = [
    {
        id: '1',
        item: 'wounds',
        postfixForYAxis: '%',
        unitType: 'percent',
        multiplier: 100,
        descimals: 2,
    },
    {
        id: '2',
        item: 'weight',
        postfixForYAxis: 'g',
        unitType: 'weight',
        multiplier: 1,
        descimals: 0,
    },
    {
        id: '3',
        item: 'femaleLice',
        postfixForYAxis: '',
        unitType: 'number',
        multiplier: 1,
        descimals: 2,
    },
    {
        id: '4',
        item: 'maturation',
        postfixForYAxis: '%',
        unitType: 'percent',
        multiplier: 1,
        descimals: 2,
    },
    {
        id: '5',
        item: 'activeWounds',
        postfixForYAxis: '%',
        unitType: 'percent',
        multiplier: 100,
        descimals: 2,
    },
    {
        id: '6',
        item: 'movingLice',
        postfixForYAxis: '',
        unitType: 'number',
        multiplier: 1,
        descimals: 2,
    },
    {
        id: '7',
        item: 'caligusLice',
        postfixForYAxis: '',
        unitType: 'number',
        multiplier: 1,
        descimals: 2,
    },
    {
        id: '8',
        item: 'swimSpeed',
        postfixForYAxis: '',
        unitType: 'number',
        multiplier: 1,
        descimals: 2,
    },
    {
        id: '9',
        item: 'conditionFactor',
        postfixForYAxis: '',
        unitType: 'number',
        multiplier: 1,
        descimals: 2,
    },
];

interface urlDataTypes {
    [key: string]: string;
}

export interface CompareContentInterfaceProps {
    urlData: {};
    locations: OverviewLocation[];
    cageIds: number[];
    isLoading: boolean;
    graphData: {};
    onCagesChanged: (cages: OverviewCage[]) => void;
}

const CompareContentPage = ({
    urlData,
    locations,
    isLoading,
    cageIds,
    graphData,
    onCagesChanged = () => {},
}: CompareContentInterfaceProps) => {
    const { t, i18n } = useTranslation();

    const [currentLocation, setCurrentLocation] = useState(null);
    const [currentLocationName, setCurrentLocationName] = useState('');
    const [addDropdownItems, setAddDropdownItems] = useState([]);
    const [currentCages, setCurrentCages] = useState([]);
    const [otherPossibleCages, setOtherPossibleCages] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(COMPARECONFIG[1]);

    const [dataWithCageNames, setDataWithCageNames] = useState({});

    useEffect(() => {
        createCageCalculations(locations);
    }, []);

    useEffect(() => {
        const allData = { ...graphData };
        const tempDataWithCageNames: { [key: string]: any } = {};

        for (const key in allData) {
            if (Object.hasOwn(allData, key)) {
                const data = allData[key];
                const newData = {};

                for (const dataKey in data) {
                    if (Object.hasOwn(data, dataKey)) {
                        const value = data[dataKey];
                        const newName = urlData[dataKey];

                        newData[newName] = value;
                    }
                }

                tempDataWithCageNames[key] = newData;
            }
        }
        setDataWithCageNames(tempDataWithCageNames);
        createCageCalculations(locations);
    }, [graphData, locations, cageIds]);

    const addCage = (cageId) => {
        const cage = otherPossibleCages.find((cage) => cage.id === cageId);
        if (cage) {
            const tempCurrentCages = [];
            currentCages.map((cage) => {
                tempCurrentCages.push(cage);
            });

            tempCurrentCages.push(cage);

            currentCages.push(cage);
            onCagesChanged(currentCages);
        }
    };

    const createCageCalculations = (locations: OverviewLocation[]) => {
        const tempOtherPossibleCages = [];
        const tempCurrentCages = [];
        const tempAddDropdownItems = [];
        let tempCurrentLocation = null;

        if (locations?.length > 0) {
            locations?.map((location) => {
                if (tempCurrentLocation === null) {
                    location.cages.map((cage) => {
                        if (
                            tempCurrentLocation === null &&
                            cageIds.find((cageId) => cageId === cage.id)
                        ) {
                            tempCurrentLocation = location;
                        }
                    });
                }
            });
        }

        if (tempCurrentLocation) {
            setCurrentLocationName(tempCurrentLocation.name);

            locations?.map((location) => {
                location.cages.map((cage) => {
                    if (location.id === tempCurrentLocation?.id) {
                        if (!cageIds.find((cageId) => cageId === cage.id)) {
                            if (cage.historical === null) {
                                tempOtherPossibleCages.push(cage);

                                const disabled = !cage.weight;
                                const noData = t('no data');
                                const cageName = disabled ? `${cage.name} (${noData})` : cage.name;
                                tempAddDropdownItems.push({
                                    label: `${location.name} ${cageName}`,
                                    value: cage.id,
                                    title: cageName,
                                    disabled: disabled,
                                });
                            }
                        } else {
                            tempCurrentCages.push(cage);
                        }
                    }
                });
            });
        } else {
            setCurrentLocationName('');
        }

        setAddDropdownItems(tempAddDropdownItems);
        setCurrentCages(tempCurrentCages);
        setOtherPossibleCages(tempOtherPossibleCages);
        setCurrentLocation(tempCurrentLocation);
    };

    function toLocale(language: string) {
        return language === 'nb' ? 'nb-NO' : language === 'es' ? 'es-ES' : 'en-US';
    }

    const [locale, setLocale] = useState('nb-NO');

    useEffect(() => {
        setLocale(toLocale(i18n.language));
    }, [i18n.language]);

    const createLinks = (data: urlDataTypes) => {
        return Object.entries(data).map(([key, value], index, array) => (
            <React.Fragment key={key}>
                <a href={`cages/v3/${key}`}>{value}</a>
                {index < array.length - 1 && ' / '}{' '}
            </React.Fragment>
        ));
    };

    const handleDropdownSelect = (option) => {
        const selectedConfigItem = COMPARECONFIG.find((item) => item.item === option);
        if (selectedConfigItem) {
            setSelectedOption(selectedConfigItem);
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Row>
                <div className={classnames(styles.headerContainer)}>
                    <h1 className="mb-3">{t('Compare pens')}</h1>
                    <SmallDropDown
                        items={addDropdownItems}
                        value={''}
                        title={t('add pen')}
                        onChange={(value) => {
                            addCage(value);
                        }}
                        disabled={!(addDropdownItems?.length > 0)}
                        scrollable={true}
                        className={classnames(styles.addPenDropdownMenu)}
                        dropDownClassname={classnames(styles.dropdownContainer)}></SmallDropDown>
                </div>
            </Row>
            <Box>
                <Row>
                    <Col xs={12}>
                        <p className="h4">
                            {t('Selected pens')} {currentLocationName}:
                        </p>
                        <h2>{createLinks(urlData)}</h2>
                    </Col>
                    <Col xs={2}></Col>
                </Row>
            </Box>

            <Box>
                <div className="pb-2 d-flex  justify-content-center align-items-center">
                    <h3 className="mb-0 border-0">{t('Comparing')}</h3>
                    <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="ml-2">
                        <DropdownToggle caret>
                            <span style={{ textTransform: 'capitalize' }}>
                                {t(selectedOption.item)}
                            </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            {COMPARECONFIG.map((option) => (
                                <DropdownItem
                                    key={option.id}
                                    onClick={() => handleDropdownSelect(option.item)}>
                                    <span style={{ textTransform: 'capitalize' }}>
                                        {t(option.item)}
                                    </span>
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <OptoMixedChart
                    chartName={t('Comparing')}
                    chartData={ComparingDataMapper({
                        data: dataWithCageNames[selectedOption.item],
                    })}
                    chartOptions={Options(defaultLineChartOptions).modify(
                        autoTimeScale({}),
                        layoutPadding({ padding: 0 }),
                        formatAxsis({
                            axsisID: 'y',
                            tickType: selectedOption.unitType,
                            descimals: selectedOption.descimals,
                            multiplier: selectedOption.multiplier,
                        }),
                        formatTooltip({
                            tickType: selectedOption.unitType,
                            multiplier: selectedOption.multiplier,
                            descimals: selectedOption.descimals,
                            locale: locale,
                        }),

                        startYAxisAtZero({
                            axsisID: 'y',
                        })
                    )}
                />
            </Box>
        </>
    );
};

export default CompareContentPage;
