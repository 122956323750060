import { graphSettings, hexToRGBA } from 'config/settings';
import i18next from 'i18next';

import { FishHealthData } from '@/routes/Client/Cage/pages/FishHealth/FishHealthColorPageContent';
import { DailyLiceCount, WeeklyLiceCount } from '@/services/types';

import { getMillisecondsFromDay, mapDaypartTagAsIndex, sortDays } from '../commonOptoChartHelpers';

const OverViewToBarChartMapper = ({ overview, dayTags = [], inactiveSetLegend = [] }) => {
    const { days } = overview;

    const tranformedMeasureCounts = mapDaypartTagAsIndex({
        days,
        chartType: 'bar',
        property: 'measCnt',
    });

    const sortedDays = sortDays(days);

    const dataSets = [];
    Object.entries(tranformedMeasureCounts).forEach(([key, value]) => {
        dataSets.push({
            data: tranformedMeasureCounts[key],
            hidden: inactiveSetLegend.includes(key),
            label: i18next.t(`daypartTag.${key}`),
            backgroundColor: hexToRGBA(graphSettings.daytagColors[key], 0.8),
            borderColor: hexToRGBA(graphSettings.daytagColors[key]),
            borderWidth: 1,
        });
    });

    return {
        labels: sortedDays,
        datasets: dataSets,
    };
};

export interface CombinedWelfareResultMapperProps {
    welfareResult: FishHealthData;
    dayTags: string[];
    inactiveSetLegend: string[];
}
const CombinedWelfareResultMapper = ({
    welfareResult,
    dayTags = [],
    inactiveSetLegend = [],
}: CombinedWelfareResultMapperProps) => {
    const { weekly } = welfareResult;

    weekly?.sort((a, b) => {
        const aWeek = `${a.isoYear}${String(a.isoWeek).padStart(2, '0')}`;
        const bWeek = `${b.isoYear}${String(b.isoWeek).padStart(2, '0')}`;
        return Number(aWeek) - Number(bWeek);
    });

    const labels = weekly.map((week) => {
        return {
            week: week.isoWeek,
            year: week.isoYear,
        };
    });

    const dataSets = [];
    const labelsWithText = labels.map((label) => {
        return `week ${label.week}`;
    });

    /* dataSets.push({
        data: weekly.map((week) => {
            return week.startedMaturation * 100;
        }),
        hidden: inactiveSetLegend.includes('startedMaturation'),
        backgroundColor: hexToRGBA(graphSettings.colors.primary, 0.7),
        borderColor: hexToRGBA(graphSettings.colors.primary),
        label: i18next.t('fishHealthIndicators.startedMaturation'),
        borderWidth: 2,
    }); */

    dataSets.push({
        data: weekly.map((week) => {
            return Number(week.fullyMature) * 100;
        }),
        hidden: inactiveSetLegend.includes('fullyMature'),
        backgroundColor: hexToRGBA(graphSettings.colors.primary, 1),
        borderColor: hexToRGBA(graphSettings.colors.primary),
        label: i18next.t('fishHealthIndicators.fullyMature'),
        borderWidth: 2,
    });

    dataSets.push({
        data: weekly.map((week) => {
            return (1 - (Number(week.startedMaturation) + Number(week.fullyMature))) * 100;
        }),
        hidden: inactiveSetLegend.includes('noMaturation'),
        backgroundColor: hexToRGBA(graphSettings.colors.dark, 0.3),
        borderColor: hexToRGBA(graphSettings.colors.primary),
        label: i18next.t('noMaturation'),
        borderWidth: 2,
    });

    return {
        labels: [...labelsWithText],
        datasets: [...dataSets],
    };
};
export interface DailyLiceCountToLiceCountBarChartMapperProps {
    liceCount: DailyLiceCount[];
    dayTags?: string[];
    inactiveSetLegend?: string[];
    isLegacyData?: boolean;
}
const DailyLiceCountToLiceCountBarChartMapper = ({
    liceCount,
    dayTags = [],
    inactiveSetLegend = [],
    isLegacyData = false,
}: DailyLiceCountToLiceCountBarChartMapperProps) => {
    const labels = liceCount.map((item) => {
        return getMillisecondsFromDay(item.day);
    });

    const data = [];
    for (const day of liceCount) {
        data.push({
            x: getMillisecondsFromDay(day.day),
            y: day.count !== null ? day.count : undefined,
        });
    }

    return {
        datasets: [
            {
                data,
                label: 'Fish count',
                backgroundColor: hexToRGBA(graphSettings.graphColors.fishCount),
                borderColor: hexToRGBA(graphSettings.graphColors.fishCount),
                borderWidth: 1,
            },
        ],
        labels: labels,
    };
};

export interface DailyLiceCountToLicePerFishBarChartMapperProps {
    liceCount: DailyLiceCount[] | WeeklyLiceCount[];
    dayTags?: string[];
    inactiveSetLegend?: string[];
    measurementSource?: 'raw' | 'legacy';
    weekly?: boolean;
}

const DailyLiceCountToLicePerFishCountBarChartMapper = ({
    liceCount,
    dayTags = [],
    inactiveSetLegend = [],
    weekly = true,
    measurementSource = 'raw',
}: DailyLiceCountToLicePerFishBarChartMapperProps) => {
    const getXaxsisTimeArray = (liceItem: DailyLiceCount | WeeklyLiceCount, weekly: boolean) => {
        if (weekly && 'weekNumber' in liceItem) {
            return liceItem.weekNumber;
        }
        if (!weekly && 'day' in liceItem) {
            return getMillisecondsFromDay(liceItem.day);
        }
    };

    const generateLabels = (liceItem: DailyLiceCount[] | WeeklyLiceCount[], weekly: boolean) => {
        return liceItem.map((item) => {
            return getXaxsisTimeArray(item, weekly);
        });
    };

    function mapData<T extends typeof liceCount>(data: T): T {
        return data.map((item) => {
            return {
                ...item,
                caligusElongatusAvg:
                    measurementSource === 'legacy'
                        ? item.caligusElongatusLegacyAvg
                        : item.caligusElongatusAvg,
                movingStageAvg:
                    measurementSource === 'legacy'
                        ? item.movingStageLegacyAvg
                        : item.movingStageAvg,
                femaleAvg: measurementSource === 'legacy' ? item.femaleLegacyAvg : item.femaleAvg,
            };
        }) as T;
    }

    const getLiceItemDates = (liceItems: DailyLiceCount[] | WeeklyLiceCount[], weekly: boolean) => {
        return liceItems.map((item) => {
            return weekly ? item.day : item.day?.split('T')[0];
        });
    };

    const createGraphData = (
        liceCount: DailyLiceCount[] | WeeklyLiceCount[],
        weekly: boolean,
        property: 'femaleAvg' | 'movingStageAvg' | 'caligusElongatusAvg'
    ) => {
        return liceCount.map((item) => {
            return weekly
                ? item !== undefined
                    ? Number(item?.[property]?.toFixed(2))
                    : 0
                : {
                      x: getXaxsisTimeArray(item, weekly),
                      y: item.femaleAvg !== undefined ? Number(item?.[property]?.toFixed(2)) : 0,
                  };
        });
    };

    if (measurementSource === 'legacy') {
        liceCount = mapData(liceCount);
    }

    const dataSets = [];

    dataSets.push({
        data: createGraphData(liceCount, weekly, 'femaleAvg'),
        backgroundColor: hexToRGBA(graphSettings.graphColors.adult_female, 0.7),
        borderColor: hexToRGBA(graphSettings.graphColors.adult_female),
        label: i18next.t('Adult female'),
        borderWidth: 2,
        lice_date: getLiceItemDates(liceCount, weekly),
        hidden: inactiveSetLegend.includes('femaleAvg'),
    });

    dataSets.push({
        data: createGraphData(liceCount, weekly, 'movingStageAvg'),
        backgroundColor: hexToRGBA(graphSettings.graphColors.mobile, 0.7),
        borderColor: hexToRGBA(graphSettings.graphColors.mobile),
        label: i18next.t('Mobile'),
        borderWidth: 2,
        hidden: inactiveSetLegend.includes('movingStageAvg'),

        lice_date: getLiceItemDates(liceCount, weekly),
    });

    dataSets.push({
        data: createGraphData(liceCount, weekly, 'caligusElongatusAvg'),
        backgroundColor: hexToRGBA(graphSettings.graphColors.caligus, 0.7),
        borderColor: hexToRGBA(graphSettings.graphColors.caligus),
        label: i18next.t('Caligus'),
        borderWidth: 2,
        hidden: inactiveSetLegend.includes('caligusElongatusAvg'),
        lice_date: getLiceItemDates(liceCount, weekly),
    });

    return {
        datasets: [...dataSets],
        labels: [...generateLabels(liceCount, weekly)].toSorted(),
    };
};

export default OverViewToBarChartMapper;
export {
    OverViewToBarChartMapper,
    CombinedWelfareResultMapper,
    DailyLiceCountToLiceCountBarChartMapper,
    DailyLiceCountToLicePerFishCountBarChartMapper,
};
