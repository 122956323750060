import React from 'react';
import { Col, Row } from 'reactstrap';
import useQueryParams from 'hooks/useQueryParams';

import PageActionButtons from '@/components/molecules/PageActionButtons';
import { PageHeaderBar } from '@/components/molecules/PageHeaderBar';
import { TroutNotice } from '@/components/organisms/TroutNotice/TroutNotice';
import useCage from '@/contexts/cage-context';
import { Loader } from 'components/atoms/Loader';
import { DownloadCsvXlsx } from 'components/DownloadCsvXlsx';
import NoData from 'components/NoData';
import SomethingHappened from 'components/SomethingHappened';

import CagePageTitle from '../../components/LocationsAndCagesNavigation';

import BioscopeStatusBox from './components/BioscopeStatusBox';
import MeasurementsCountBox from './components/MeasurementsCountBox';
import OverviewTour from './components/OverviewTour';
import SceneLabels from './components/SceneLabels';
import StatusBar2 from './components/StatusBar2';
import WeightAndGrowBox from './components/WeightAndGrowBox';
import WeightBox from './components/WeightBox';
import { useRestOverview } from './overview-context';

const DEFAULT_DAYPART_TAG_FOR_DOWNLOAD = 'day';
function Overview() {
    const { loading, error, data: overview } = useRestOverview();
    const { isTrout } = useCage();
    const queryParams = useQueryParams();
    const daypartTag = queryParams[0]?.d ? queryParams[0].d : DEFAULT_DAYPART_TAG_FOR_DOWNLOAD;
    const dailyOverview = overview?.days;
    const dailyOverviewForDownload =
        dailyOverview?.map((el) => ({
            date: el?.day,
            daypartTag: el?.daypartTag,
            measurementsCount: el?.data.measCnt,
            weightAvg: el?.data.weightAvg?.toFixed(2),
            weightTrend: el?.data.weightTrend?.toFixed(2),
            cfAvg: el?.data.cfAvg?.toFixed(2),
            cfTrend: el?.data.cfTrend?.toFixed(2),
            fishLengthAvg: el?.data.fishLengthAvg?.toFixed(2),
            fishHeightAvg: el?.data.fishHeightAvg?.toFixed(2),
            cv: el?.data.cv?.toFixed(2),
            weightGrowthAvg: el?.data.weightGrowthAvg?.toFixed(2),
            weightGrowthTrend: el?.data.weightGrowthTrend?.toFixed(2),
            depthMin: el?.data.depthMin?.toFixed(2),
            depthMax: el?.data.depthMax?.toFixed(2),
            depthAvg: el?.data.depthAvg?.toFixed(2),
        })) ?? [];
    const filteredByDayPartTag =
        dailyOverviewForDownload.filter((item) => item?.daypartTag === daypartTag) ?? [];

    // need to send columns names in this format
    const locationInfoColumns = [
        'location.name',
        'location.timezone',
        'location.sWeightPct',
        'cage.name',
    ];
    const dataColumnNames =
        dailyOverviewForDownload.length > 0 ? Object.keys(dailyOverviewForDownload[0]) : [];
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    const allColumnNames = [...locationInfoColumns, ...formattedDataColumnNames];

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <SomethingHappened error={error} />;
    }

    if (!overview) {
        return (
            <>
                <PageHeaderBar>
                    <CagePageTitle />
                </PageHeaderBar>

                <Row>
                    <Col>
                        <NoData />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <BioscopeStatusBox />
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <>
            {isTrout && <TroutNotice />}
            <PageHeaderBar>
                <CagePageTitle />
                <PageActionButtons>
                    <OverviewTour />
                    <DownloadCsvXlsx
                        label="CSV"
                        data={filteredByDayPartTag}
                        COLUMNS={allColumnNames}
                    />
                    <DownloadCsvXlsx
                        label="XLSX"
                        data={filteredByDayPartTag}
                        COLUMNS={allColumnNames}
                    />
                </PageActionButtons>
            </PageHeaderBar>

            <Row>
                <Col>
                    <StatusBar2 />
                </Col>
            </Row>
            <Row>
                <Col xl={8}>
                    <WeightBox />
                    <MeasurementsCountBox />
                    <BioscopeStatusBox />
                </Col>
                <Col xl={4}>
                    <SceneLabels />
                    <WeightAndGrowBox />
                </Col>
            </Row>
        </>
    );
}

export default Overview;
