import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';
import moment from 'moment';

import SomethingHappened from '@/components/SomethingHappened';
import { useClientController_Compare, useClientController_Overview } from '@/services/hooks';
import { OverviewCage, OverviewLocation } from '@/services/types';
import { DISPLAY_DAY_FORMAT } from '@/utils/formattedDay';
import { LeftColumn, Page, PageContent } from 'components/Layout';

import CageFilterCompare from './CageFilterCompare';
import CompareContentPage from './CompareContentPage';

import styles from './CompareContainer.module.scss';

const getUrlParameters = (search: string) => {
    const params = new URLSearchParams(search);
    const dataParam = params.get('data');

    if (!dataParam) {
        return [];
    }
    const dataArray = dataParam.split(',');
    const result = dataArray.reduce((result, item) => {
        const [key, value] = item.split('~');
        result[value] = key;
        return result;
    }, {});
    return result;
};

const CompareContainer = ({ clientId }: { clientId: number }) => {
    const [urlData, setUrlData] = useState({});

    const urlSearchPath = window.location.search;
    const params = new URLSearchParams(urlSearchPath);
    const toParam = params.get('to');
    const fromParam = params.get('from');
    const dayartTagParam = params.get('daypartTag');

    const [submittedData, setSubmittedData] = useState<{
        from?: string;
        to?: string;
        daypartTag?: string;
    }>({ from: fromParam, to: toParam, daypartTag: dayartTagParam ? dayartTagParam : 'day' });

    const history = useHistory();

    const {
        isLoading: isLoadingClientLocations,
        data,
        error,
        refetch,
    } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });

    const url = window.location.search;
    const urlDataFromParams = getUrlParameters(url);

    const cageIdsFromParams = Object.keys(urlDataFromParams).map(Number);

    const {
        data: compareData,
        isLoading: compareIsLoading,
        error: compareError,
    } = useClientController_Compare({
        cages: cageIdsFromParams,
        from: submittedData?.from
            ? moment(submittedData.from, DISPLAY_DAY_FORMAT).toISOString()
            : null,
        to: submittedData?.to ? moment(submittedData.to, DISPLAY_DAY_FORMAT).toISOString() : null,
        daypartTag: submittedData?.daypartTag || 'day',
    });

    useEffect(() => {
        const url = window.location.search;
        setUrlData(getUrlParameters(url));
    }, []);

    const handleFormSubmit = useCallback((from: string, to: string, daypartTag: string) => {
        const urlSearchPath = window.location.search;
        const params = new URLSearchParams(urlSearchPath);

        const dataParamPart = params.get('data');

        const prevFrom = params.get('from');
        const prevTo = params.get('to');
        const prevDaypartTag = params.get('daypartTag');

        if (prevFrom === from && prevTo === to && prevDaypartTag === daypartTag) {
            return;
        }

        let nextQueryPath = `data=${dataParamPart}`;

        const dateQueryParams: string[] = [];

        if (to) {
            dateQueryParams.push(`to=${moment(to).format(DISPLAY_DAY_FORMAT)}`);
        }
        if (from) {
            dateQueryParams.push(`from=${moment(from).format(DISPLAY_DAY_FORMAT)}`);
        }
        if (daypartTag) {
            dateQueryParams.push(`daypartTag=${daypartTag}`);
        } else {
            dateQueryParams.push('daypartTag=day');
        }

        if (dateQueryParams.length > 0) {
            const joinedDateQueryParams = dateQueryParams.join('&');
            nextQueryPath = `${nextQueryPath}&${joinedDateQueryParams}`;
        }

        nextQueryPath = `?${nextQueryPath}`;
        if (nextQueryPath === urlSearchPath) {
            return;
        }

        const nextPath = `/c/${clientId}/compare${nextQueryPath}`;
        history.push(nextPath);
    }, []);

    const handleCagesChanged = (cages: OverviewCage[]) => {
        const queryString = cages
            .map((item) => `${encodeURIComponent(item.name)}~${encodeURIComponent(item.id)}`)
            .join(',');
        let nextQueryPart = `?data=${queryString}`;

        const urlSearchPath = window.location.search;
        const params = new URLSearchParams(urlSearchPath);
        const toParam = params.get('to');
        const fromParam = params.get('fromParam');
        const dayartTagParam = params.get('daypartTag');

        const dateQueryParams: string[] = [];
        if (toParam) {
            dateQueryParams.push(`to=${toParam}`);
        }
        if (fromParam) {
            dateQueryParams.push(`from=${fromParam}`);
        }
        if (dayartTagParam) {
            dateQueryParams.push(`daypartTag=${dayartTagParam}`);
        } else {
            dateQueryParams.push('daypartTag=day');
        }

        if (dateQueryParams.length > 0) {
            const joinedDateQueryParams = dateQueryParams.join('&');
            nextQueryPart = `${nextQueryPart}&${joinedDateQueryParams}`;
        }

        nextQueryPart = `${nextQueryPart}`;
        if (urlSearchPath === nextQueryPart) {
            return;
        }

        const nextPath = `/c/${clientId}/compare${nextQueryPart}`;

        history.push(nextPath);
    };

    if (error || compareError) {
        return <SomethingHappened />;
    }

    return (
        <Page title={'Compare'}>
            <LeftColumn>
                <nav className={styles.leftNavigationPanel}>
                    <NavbarBrand tag={Link} to="/">
                        <figure>
                            <img src="/logo.png" alt="" />
                        </figure>
                    </NavbarBrand>
                    <CageFilterCompare
                        defaultTo={submittedData.to}
                        defaultFrom={submittedData.from}
                        defaultDaypartTag={submittedData.daypartTag}
                        onFormSubmit={handleFormSubmit}
                    />
                </nav>
            </LeftColumn>
            <PageContent>
                <CompareContentPage
                    locations={data?.data?.locations as OverviewLocation[]}
                    graphData={compareData?.data}
                    urlData={urlData}
                    cageIds={cageIdsFromParams}
                    onCagesChanged={handleCagesChanged}
                    isLoading={compareIsLoading || isLoadingClientLocations}
                />
            </PageContent>
        </Page>
    );
};

export default CompareContainer;
export { CompareContainer };
