import React from 'react';

import styles from './page.module.scss';

interface PageProps {
    title: string;
    children?: any;
}

const Page = ({ title, children }: PageProps) => {
    //useDocumentTitle(title);

    return (
        <main className={styles.page} data-pagetitle={title}>
            {children}
        </main>
    );
};

export default Page;
export { Page };
