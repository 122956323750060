import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './i18n';

import SentryErrorBoundary from './components/SentryErrorBoundary';
import AuthProvider from './containers/AuthProvider';
import HeaderProvider from './containers/HeaderProvider';
import Routes from './routes';

import 'react-toastify/dist/ReactToastify.css';
import '@/components/molecules/ToastTemplate/ToastTemplate_cssvariables.scss';

//Apollo client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: 1000 * 60 * 5,
            cacheTime: 1000 * 60 * 10,
        },
    },
});

const Root = () => {
    return (
        <SentryErrorBoundary>
            <BrowserRouter>
                <QueryClientProvider client={queryClient} contextSharing={true}>
                    <AuthProvider>
                        <HeaderProvider>
                            <ToastContainer containerId="optoscale-toast" draggable={false} />
                            <Routes />
                        </HeaderProvider>
                    </AuthProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </SentryErrorBoundary>
    );
};

export default Root;
