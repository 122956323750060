import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useCage } from 'contexts/cage-context';
import { useQueryParams } from 'hooks/useQueryParams';
import type queryString from 'query-string';
import { type ColorValuesDay, ImageFeedbackDto } from 'services/types';

import Loader from '@/components/atoms/Loader';
import PageActionButtons from '@/components/molecules/PageActionButtons';
import PageHeaderBar from '@/components/molecules/PageHeaderBar';
import { getLabelByValue } from '@/components/molecules/ShareFalseDetectionDropdown/ShareFalseDetectionDropDownObjects';
import { ToastTemplateBackendFeedback } from '@/components/molecules/ToastTemplate';
import { CombinedFishHealthWithTemperatureData } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import { useCageFilter } from '@/contexts/cage-filter-context';
import { useMe } from '@/contexts/meContext';
import { fullSizeImageMapper, ImageState, useImageState } from '@/hooks/image-state';
import { placeHolder } from '@/services/config';
import {
    useCombinedWelfareController_FullWelfare,
    useFeedbackController_ImageFeedback,
    useLiceController_GetTemperature,
    useWelfareController_allImages,
} from '@/services/hooks';
import tsToString from '@/utils/tsToString';
import { DownloadCsvXlsx } from 'components/DownloadCsvXlsx';
import { FILTER_TAGS } from 'components/organisms/ImageViewer';

import CagePageTitle from '../../components/LocationsAndCagesNavigation';

import { FishHealthColorPageContent, type FishHealthData } from './FishHealthColorPageContent';

const downloadableData = (
    dailyHealthData: ColorValuesDay[],
    queryParams?: queryString.ParsedQuery<string>
) => {
    const DEFAULT_DAYPART_TAG_FOR_DOWNLOAD = 'day';
    const daypartTagForDownload = queryParams?.d ? queryParams.d : DEFAULT_DAYPART_TAG_FOR_DOWNLOAD;
    const dailyHealthDataForDownload =
        dailyHealthData?.map((el) => ({
            date: el?.day,
            daypartTag: daypartTagForDownload,
            count: el?.count,
            wound: el?.wound?.toFixed(2),
            scaleLoss: el?.skinSpeckles?.toFixed(2),
            startedMaturation: el?.startedMaturation?.toFixed(2),
            fullyMatured: el?.fullyMature?.toFixed(2),
            smallWound: el?.smallWound?.toFixed(2),
            mediumWound: el?.mediumWound?.toFixed(2),
            bigWound: el?.bigWound?.toFixed(2),
            activeWounds: el?.hasActiveWounds?.toFixed(2),
            healingWounds: el?.hasHealingAndNotActiveWounds?.toFixed(2),
            singleWound: el?.oneWound?.toFixed(2),
            moreThanOneWound: el?.moreThanOneWound?.toFixed(2),
        })) ?? [];
    // need to send columns names in this format
    const locationInfoColumns = [
        'location.name',
        'location.timezone',
        'location.sWeightPct',
        'cage.name',
    ];
    const dataColumnNames =
        dailyHealthDataForDownload.length > 0 ? Object.keys(dailyHealthDataForDownload[0]) : [];
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    const allColumnNames = [...locationInfoColumns, ...formattedDataColumnNames];
    return { dailyHealthDataForDownload, allColumnNames };
};

const FishHealthColorContainer = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { isTrout } = useCage();
    const { isOptoscaleAdmin, state } = useMe();
    const {
        client: { id: clientId },
    } = useCage();
    const { healthType } = useParams();
    const updatedHealthType = healthType ?? (isTrout ? 'maturation' : 'wounds');

    const { cageId, timezone, loading: cageLoading, hasColorWelfare } = useCage();
    const { normalizedFrom, normalizedTo, daypartTag } = useCageFilter();
    const from = normalizedFrom ? tsToString(normalizedFrom, timezone) : undefined;
    const to = normalizedTo ? tsToString(normalizedTo, timezone) : undefined;

    const { mutateAsync: reportFeedbackAsync } = useFeedbackController_ImageFeedback();

    // Load data
    const { data: fishHealth, isLoading: fishHealthLoading } =
        useCombinedWelfareController_FullWelfare(
            cageId,
            { from: from, to: to, daypartTag: daypartTag },
            { staleTime: 5 * 60 * 1000 }
        );
    // Load list of dates with images
    // color-welfare-image
    const type = hasColorWelfare ? 'color-welfare-image' : 'bw-welfare';

    const { data: imagesdata, isLoading: imagesdataLoading } = useWelfareController_allImages(
        cageId,
        { type },
        { placeholderData: placeHolder([]), keepPreviousData: true }
    );

    const imageStateByTag = FILTER_TAGS.reduce(
        (acc, tag) => {
            acc[tag.tag] = useImageState({
                loading: imagesdataLoading,
                // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
                mapper: useCallback(fullSizeImageMapper(cageId), [cageId]),
                data: imagesdata?.data,
                queryParam: 'imageId',
                timezone: timezone,
                filter: tag.filter,
            });
            return acc;
        },
        {} as Record<string, ImageState>
    );

    const { data: temperature } = useLiceController_GetTemperature(cageId, {
        daypartTag: daypartTag,
        weightUnitTag: 'g',
    });

    const combinedFishHealthData = hasColorWelfare ? fishHealth?.data.color : fishHealth?.data.bw;

    const loading = cageLoading || fishHealthLoading;
    const combinedFishHealthWithTemperatureData = combinedFishHealthData
        ? CombinedFishHealthWithTemperatureData({
              combinedFishHealthData: combinedFishHealthData,
              temperature: temperature?.data ?? [],
          })
        : undefined;

    const onCommitImageFeedbackDto = async (feedbackDto: ImageFeedbackDto) => {
        if (state?.user?.id) {
            feedbackDto = { ...feedbackDto, reportingUserId: state.user.id };
        }

        if (cageId) {
            feedbackDto = { ...feedbackDto, cageId: cageId };
        }

        const { status, data } = await reportFeedbackAsync({ requestBody: feedbackDto });
        if (status) {
            ToastTemplateBackendFeedback({
                content: `Thank you ${
                    state?.user?.firstName ?? ''
                } for contributing to train our AI model on: ${t(getLabelByValue(data.tag) || '')}`,
                toastId: 'detection-feedback',
                httpStatusCode: status,
            });
        }
    };

    const [queryParams] = useQueryParams();
    const { dailyHealthDataForDownload, allColumnNames } = downloadableData(
        fishHealth?.data?.color?.days as ColorValuesDay[],
        queryParams
    );

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <PageHeaderBar>
                <CagePageTitle />
                <PageActionButtons>
                    <DownloadCsvXlsx
                        label="CSV"
                        data={dailyHealthDataForDownload}
                        COLUMNS={allColumnNames}
                    />
                    <DownloadCsvXlsx
                        label="XLSX"
                        data={dailyHealthDataForDownload}
                        COLUMNS={allColumnNames}
                    />
                </PageActionButtons>
            </PageHeaderBar>
            {!combinedFishHealthWithTemperatureData && fishHealth && loading ? null : (
                <FishHealthColorPageContent
                    source={fishHealth.data.source as unknown as string}
                    fishHealthData={combinedFishHealthWithTemperatureData as FishHealthData}
                    healthType={updatedHealthType}
                    imageStateByTag={imageStateByTag}
                    isOptoscaleAdmin={isOptoscaleAdmin}
                    onCommitFalseDetection={onCommitImageFeedbackDto}
                />
            )}
        </>
    );
};

export default FishHealthColorContainer;
export { FishHealthColorContainer };
