import { Link } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';
import { useCage } from 'contexts/cage-context';

import { Loader } from 'components/atoms/Loader';
import { LeftColumn, Page, PageContent } from 'components/Layout';
import SomethingHappened from 'components/SomethingHappened';

import CageFilter from './components/CageFilter';
import CageRoutes from './components/CageRoutes';
import { CageSideBarNavigation } from './components/CageSideBarNavigation';
import pages from './pages';

import styles from './Cage.module.scss';

function Cage() {
    const { loading, error } = useCage();

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <SomethingHappened />;
    }

    return (
        <Page title="Cage">
            <LeftColumn>
                <nav className={styles.leftNavigationPanel}>
                    <NavbarBrand tag={Link} to="/" className={styles.logo}>
                        <figure>
                            <img src="/logo.png" alt="" />
                        </figure>
                    </NavbarBrand>

                    <CageSideBarNavigation pages={pages} />
                    <CageFilter />
                </nav>
            </LeftColumn>
            <PageContent>
                <CageRoutes pages={pages} />
            </PageContent>
        </Page>
    );
}

export default Cage;
