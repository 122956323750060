import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { BioscopeImageCardContainer } from 'routes/Client/BioscopeDetail/BioscopeImageCardContainer';
import { BioscopeDetail as BioscopeDetailTypes } from 'services/types';

import { Box } from '@/components/Layout';
import { LastMeasurement } from 'components/atoms/LastMeasurement/LastMeasurement';
import { LastPlacement } from 'components/atoms/LastPlacement/LastPlacement';
import Loader from 'components/atoms/Loader';
import { PageTitle } from 'components/Layout/PageTitle';
import { BioscopeConfiguration } from 'components/molecules/BioscopeConfiguration/BioscopeConfiguration';
import { BioscopeConnection } from 'components/molecules/BioscopeConnection/BioscopeConnection';
import { BioscopeMeta } from 'components/molecules/BioscopeMeta/BioscopeMeta';
import { BioscopePosition } from 'components/molecules/BioscopePosition/BioscopePosition';
import BioscopeRestarts, {
    BioscopeRestartsProps,
} from 'components/molecules/BioscopeRestarts/BioscopeRestarts';
import { CurrentBioscopePlacement } from 'components/molecules/CurrentBioscopePlacement/CurrentBioscopePlacement';
import NoData from 'components/NoData';

import styles from './BioscopeDetail.module.scss';

interface BioscopeDetailProps {
    isBioscopeDetailLoading: boolean;
    isOptoscaleAdmin: boolean;
    bioscope: BioscopeDetailTypes;
    BioscopeConfig?: {
        LUX?: number;
        bioscopeId?: number;
        depth?: number;
        light_intensity?: number;
        pitch?: number;
        roll?: number;
        temperature?: number;
    };
    canSubmitBioscopeConfiguration?: boolean;

    deleteLastBioscopeMove?: () => void;
    moveBioscope?: () => void;
    bioscopeRestarts: BioscopeRestartsProps;
    bioscopeRouterRestarts: BioscopeRestartsProps;

    queryTypes?: {
        CAGE?: string;
        BIOSCOPE?: string;
        UNCLASSIFIED?: string;
    };
}

const BioscopeDetail = ({
    bioscopeRestarts,
    bioscopeRouterRestarts,
    isBioscopeDetailLoading,
    bioscope,
    deleteLastBioscopeMove,
    isOptoscaleAdmin,
    BioscopeConfig,
    canSubmitBioscopeConfiguration,
    moveBioscope,
    queryTypes,
}: BioscopeDetailProps) => {
    const { t } = useTranslation();

    if (isBioscopeDetailLoading) {
        return <Loader />;
    }

    if (!bioscope) {
        return <NoData />;
    }

    return (
        <Container className={styles.bioscopeDetail}>
            <PageTitle showH1={true} title={`${t('Bioscope')} ID: ${bioscope.id}`} />

            <div className={styles.bioscopeDetailPanelWrapper}>
                <CurrentBioscopePlacement
                    loading={false}
                    error={false}
                    errorText="Error updating bioscope move data"
                    title="Placed With"
                    client={bioscope.client}
                    deleteLastBioscopeMove={deleteLastBioscopeMove}
                    moveBioscope={moveBioscope}
                />
                <LastPlacement title="Last placement" lastLocation={bioscope.lastLocation} />
                <LastMeasurement lastMeasurement={bioscope.lastMeasurement} />
            </div>
            <div className={styles.bioscopeDetailPanelWrapper}>
                <BioscopeMeta
                    bioscopeMetaData={bioscope.version}
                    isLoading={isBioscopeDetailLoading}
                />
                {queryTypes && (
                    <div className={styles.BioscopeImageCardContainer}>
                        <BioscopeImageCardContainer bioscope={bioscope} />
                    </div>
                )}
            </div>
            {isOptoscaleAdmin && (
                <div className={styles.bioscopeDetailPanelWrapperFullWidth}>
                    <BioscopeConfiguration
                        isOptoscaleAdmin={isOptoscaleAdmin}
                        BioscopeConfig={BioscopeConfig}
                        canSubmitBioscopeConfiguration={canSubmitBioscopeConfiguration}
                    />
                </div>
            )}
            <div className={styles.bioscopeDetailPanelWrapperFullWidth}>
                <BioscopeConnection connection={bioscope.connection} />
            </div>
            <div className={styles.bioscopeDetailPanelWrapperFullWidth}>
                <Box title={t('Bioscope restarts')}>
                    <BioscopeRestarts {...bioscopeRestarts} />
                </Box>
            </div>
            <div className={styles.bioscopeDetailPanelWrapperFullWidth}>
                <Box title={t('Bioscoperouter  restarts')}>
                    <BioscopeRestarts {...bioscopeRouterRestarts} />
                </Box>
            </div>

            <div className={styles.bioscopeDetailPanelWrapperFullWidth}>
                <BioscopePosition bioscopeLocations={bioscope.locations} />
            </div>
        </Container>
    );
};

export default BioscopeDetail;
export { BioscopeDetail };
