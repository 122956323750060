// import { Loader } from 'components/atoms/Loader';
import { MeasurmentOverviewResult } from 'services/types';
import { getTimeUnitFromTimeRange } from 'utils';

import Loader from '@/components/atoms/Loader';
import PageHeaderBar from '@/components/molecules/PageHeaderBar';
import useCageFilter from '@/contexts/cage-filter-context';
import { dayTagValues } from 'components/organisms/OptoGraphs/commonOptoChartHelpers';
import SomethingHappened from 'components/SomethingHappened';

import useCage from '../../../../../contexts/cage-context';
import CagePageTitle from '../../components/LocationsAndCagesNavigation';
import useGraphData from '../Overview2/graph-data-context';

import { FishSpeedPageContent } from './fishSpeedPageContent';

const FishSpeed = () => {
    const { daypartTag = 'day', error, loading, weightUnitTag, data } = useGraphData();
    const { weightUnitTag: tag, daypartTag: dayPart, to, from } = useCageFilter();
    const { cageId, timezone, loading: cageLoading } = useCage();

    // Fetch chosen daytag from menu date time filter.
    const disabeledDayTags = dayTagValues.filter((dayTag) => dayTag !== dayPart);

    const pageComponentData = {
        disabeledDayTags,
        loading,
        data: data as MeasurmentOverviewResult,
        weightUnitTag: tag as 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%',
        daypartTag: dayPart,
        timeUnitTag: getTimeUnitFromTimeRange(from, to),
        /* NB NB - server does not return the correct dataset in staging. Not able to return from last year */
    };

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <SomethingHappened />;
    }

    return (
        <>
            <PageHeaderBar>
                <CagePageTitle />
            </PageHeaderBar>
            <FishSpeedPageContent {...pageComponentData} />
        </>
    );
};

export default FishSpeed;
export { FishSpeed };
