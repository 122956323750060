import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row, Table } from 'reactstrap';
import { orderBy } from 'lodash';

import { Box, PageTitle } from 'components/Layout';
import { ModalAddClient } from 'components/molecules/ModalAddClient';

const Clients = ({ clients, onSubmitted, ...props }) => {
    const { t } = useTranslation();

    return (
        <Container fluid>
            <Row>
                <Col className="text-center">
                    <PageTitle showH1={true} title={t('Clients')} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box>
                        <ModalAddClient onSubmitted={onSubmitted} />
                        <Table hover className="mt-2">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>{t('Name')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderBy(clients, ['name'], ['asc']).map((client) => (
                                    <tr
                                        key={client.id}
                                        onClick={() =>
                                            props.history.push(`${props.match.url}/${client.id}`)
                                        }>
                                        <td>{client.id}</td>
                                        <td>{client.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Box>
                </Col>
            </Row>
        </Container>
    );
};

export default Clients;
export { Clients };
